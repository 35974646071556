<template>
	<!-- RULES ADD/EDIT MODAL -->
	<b-modal id="modal-capture-schedule" ref="modal-capture-schedule" @show="showModal" @hidden="hideModal">

		<template #modal-title>
			<h3>Automatic Active/Pause Capture</h3>
		</template>

		<b-overlay :show="isFetchingDataFromServe" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">

			<b-card no-body v-if="prop_settingObject.status == 1 || prop_settingObject.status == 0">
				<b-card-header>
					<b-card-title>Active Capture Schedule</b-card-title>
				</b-card-header>
				<b-card-body>
					<b-row>

						<b-col cols="12">
							<b-form-group label="Select date & time to Active (EDT time)">
								<VueCtkDateTimePicker id="active-capture-schedule" v-model="autoEnableDateTime" :overlay="true" :noLabel="true" :noShortcuts="true" :format="'YYYY-MM-DD hh:mm a'" :formatted="'MM-DD-YYYY - hh:mm A'" :minDate="currentDate" minute-interval="5" @is-hidden="hideActiveScheduleDateAndTimePopup($event)" />
							</b-form-group>
						</b-col>

						<b-col lg="12">
							<h5 class="text-danger"><strong class="badge badge-light-danger">Note:</strong></h5>
							<ul>
								<i><span class="text-danger">(1) Capture will never get activated if the date/time is not selected.</span></i><br>
								<i><span class="text-danger">(2) If capture is active and schedule is created for future date/time, then the Active capture will get paused and will get activated on schedule date/time.</span></i>
							</ul>
						</b-col>

						<b-col cols="12">
							<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1 float-right" @click="saveScheduleCapture('active')">
								Submit
							</b-button>
						</b-col>

					</b-row>
				</b-card-body>
			</b-card>

			<b-card no-body v-if="prop_settingObject.status == 1">
				<b-card-header>
					<b-card-title>Pause Capture Schedule</b-card-title>
				</b-card-header>
				<b-card-body>
					<b-row>

						<b-col cols="12">
							<b-form-group label="Select date & time to Pause (EDT time)">
								<VueCtkDateTimePicker id="pause-capture-schedule" v-model="autoDisableDateTime" :overlay="true" :noLabel="true" :noShortcuts="true" :format="'YYYY-MM-DD hh:mm a'" :formatted="'MM-DD-YYYY - hh:mm A'" :minDate="currentDate" minute-interval="5" @is-hidden="hidePauseScheduleDateAndTimePopup($event)" />
							</b-form-group>
						</b-col>

						<b-col lg="12">
							<h5 class="text-danger"><strong class="badge badge-light-danger">Note:</strong></h5>
							<ul>
								<i class="text-danger">It will never pause if the date/time is not selected.</i>
							</ul>
						</b-col>

						<b-col cols="12">
							<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1 float-right" @click="saveScheduleCapture('pause')">
								Submit
							</b-button>
						</b-col>

					</b-row>
				</b-card-body>
			</b-card>

		</b-overlay>

		<template #modal-footer="{ ok, cancel, hide }">
			<b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" @click="cancel()">
				Close
			</b-button>
		</template>

	</b-modal>
</template>

<script>
	import {
		BCardHeader, BCardTitle, BCardBody, BFormInput, BInputGroup, BModal, BButton, BForm, BFormGroup, BCardText, BCol, BAlert, BRow, BInputGroupAppend, BInputGroupPrepend, BCard, BContainer, BOverlay
	} from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import moment from 'moment-timezone';
	import useAppConfig from '@core/app-config/useAppConfig'

	export default {
		components: {
			BFormInput, BInputGroup, BModal, BButton, BForm, BFormGroup, BCardText, BCol, BAlert, BRow, BInputGroupAppend, BInputGroupPrepend, BCard, BCardBody, BCardHeader, BContainer, BCardTitle, BOverlay,
		},
		directives: {
			Ripple,
		},
		props: {
			prop_selectedFilters: {
				type: Object,
				default: () => { },
			},

			prop_settingObject: {
				type: Object,
				default: () => { },
			},
		},
		data() {
			return {
				isFetchingDataFromServe: true,
				currentDate: moment().tz("US/Eastern").format("YYYY-MM-DD hh:mm a"),
				autoEnableDateTime: '',
				autoDisableDateTime: '',
				captureStatus: null
			}
		},
		methods: {
			/**
			 * FOR SHOW BOOTSTRAP MODAL
			 */
			showModal() {
				/* WHEN USER HAVE SELECTED TAB 'paused' THEN HIDE PAUSE CAPTURE SCHEDULE SECTION : START */
				// if (this.prop_selectedFilters.statisticsItems) {
				// 	this.showPauseSection = this.prop_selectedFilters.statisticsItems.name == 'paused' ? false : true;
				// }
				/* WHEN USER HAVE SELECTED TAB 'paused' THEN HIDE PAUSE CAPTURE SCHEDULE SECTION : END */

				this.$manageCaptureAPI.get('fetch-active-pause-schedule-datetime', {
					settingId: this.prop_settingObject.settingId
				}).then((response) => {
					response = response.data;
					if (response.status) {
						this.autoEnableDateTime = (response.setting.auto_enable_date_time) ? moment(response.setting.auto_enable_date_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm a') : '';
						this.autoDisableDateTime = (response.setting.auto_disable_date_time) ? moment(response.setting.auto_disable_date_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm a') : '';
						this.captureStatus = response.status
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
					this.isFetchingDataFromServe = false;
				});
			},

			/**
			 * FOR HIDE BOOTSTRAP MODAL
			 */
			hideModal() {
			},

			/**
			 * FOR HIDE DATE/TIME POPUP (ACTIVE SCHEDULE)
			 */
			hideActiveScheduleDateAndTimePopup() {

			},

			/**
			 * FOR HIDE DATE/TIME POPUP (PAUSE SCHEDULE)
			 */
			hidePauseScheduleDateAndTimePopup() {

			},

			/**
			 * FOR STORE ACTIVE OR PAUSE CAPTURE SCHEDULE
			 */
			saveScheduleCapture(scheduleType) {

				/* SUBMIT OBJECT : START */
				let submitData = new Object();
				submitData['settingId'] = this.prop_settingObject.settingId;

				if (scheduleType == 'active') { /* FOR ACTIVE SCHEDULE-CAPTURE */
					submitData['enable_setting_time'] = this.autoEnableDateTime ?? ''
				} else { /* FOR PAUSE SCHEDULE-CAPTURE */
					submitData['disable_setting_time'] = this.autoDisableDateTime ?? ''
				}
				/* SUBMIT OBJECT : END */

				this.$manageCaptureAPI.post('save-active-pause-schedule-capture', submitData).then((response) => {
					response = response.data;

					if (response.status) {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Scheduled',
								icon: 'BellIcon',
								variant: 'success',
								text: 'Capture has been Scheduled',
							},
						});
						this.$bvModal.hide('modal-capture-schedule');
						submitData['scheduleType'] = scheduleType
						this.$emit('onSaveCaptureScheduleEvent', submitData);
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
				});
			}
		},
		computed: {
			isDarkMode() {

			}
		},
		watch: {
			/**
			 * FOR SELCTED FILTER LIKE CURRENT TAB AND CURRENT DATE TYPE
			 */
			prop_selectedFilters: (newVal) => {
				return newVal;
			},

			/**
			 * FOR SETTING OBJECT
			 */
			prop_settingObject: (newVal) => {
				return newVal;
			},
		},
	}
</script>
<style lang="scss">
</style>