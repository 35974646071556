import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'
export default function fortuneWheelWinningObject() {
	const refDatatableFortuneWheelWinningView = ref(null)
	const tableColumns = [
		{ key: 'title', label: "Title" },
		{ key: 'couponCode', label: "Coupon Code", tdClass: 'text-center', thClass: 'text-center' },
		{ key: 'validTill', label: "Valid till", tdClass: 'text-center', thClass: 'text-center' },
		{ key: 'winningInterval', label: 'Winning Interval', formatter: title, ortable: true, tdClass: 'text-center', thClass: 'text-center' },
		{ key: 'total_winning', label: "Winning Count", tdClass: 'text-center', thClass: 'text-center' },
	]
	const perPage = ref(10)
	const totalUsers = ref(0)
	const currentPage = ref(1)
	const searchQuery = ref('')
	const sortBy = ref('id')
	const isSortDirDesc = ref(true)
	const roleFilter = ref(null)
	const planFilter = ref(null)
	const statusFilter = ref(null)
	const refetchData = () => {
		refDatatableFortuneWheelWinningView.value.refresh()
	}
	watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
		refetchData()
	})
	// *===============================================---*
	// *--------- UI ---------------------------------------*
	// *===============================================---*
	const resolveUserRoleVariant = role => {
		if (role === 'subscriber') return 'primary'
		if (role === 'author') return 'warning'
		if (role === 'maintainer') return 'success'
		if (role === 'editor') return 'info'
		if (role === 'admin') return 'danger'
		return 'primary'
	}
	const resolveUserRoleIcon = role => {
		if (role === 'subscriber') return 'UserIcon'
		if (role === 'author') return 'SettingsIcon'
		if (role === 'maintainer') return 'DatabaseIcon'
		if (role === 'editor') return 'Edit2Icon'
		if (role === 'admin') return 'ServerIcon'
		return 'UserIcon'
	}
	const resolveUserStatusVariant = status => {
		if (status === 'pending') return 'warning'
		if (status === 'active') return 'success'
		if (status === 'inactive') return 'secondary'
		return 'primary'
	}
	return {
		tableColumns,
		perPage,
		currentPage,
		totalUsers,
		sortBy,
		isSortDirDesc,
		refDatatableFortuneWheelWinningView,
		resolveUserRoleVariant,
		resolveUserRoleIcon,
		resolveUserStatusVariant,
		refetchData,
		// Extra Filters
		roleFilter,
		planFilter,
		statusFilter,
	}
}