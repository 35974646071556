import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useUsersList() {
    const toast = useToast()
    const refUserListTable = ref(null)
    const tableColumns = [
		{ key: 'captureBarSay', label: "Capture Text" },
		{ key: 'age', label: "Age" },
		{ key: 'subGoal', label: "Type" },
		{ key: 'styleType', label: 'Style', formatter: title, ortable: true },
		{ key: 'views', label: "Views", tdClass: 'text-center' },
		{ key: 'conversions', label: "Conversions", tdClass: 'px-0 text-center' },
		{ key: 'conversionsPercentage', label: "Percentage(%)", tdClass: 'text-center' },
		{ key: 'comparison', label: "Comparison", tdClass: 'text-center' },
		{ key: 'duplicate_alphabet', label: "Duplicate", tdClass: 'text-center' },
		{ key: 'site_setting_notes', label: "Notes" },
		{ key: 'actions' },
	]
    const perPage = ref(10)
    const totalUsers = ref(0)
    const currentPage = ref(1)
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const roleFilter = ref(null)
    const planFilter = ref(null)
    const statusFilter = ref(null)
    const refetchData = () => {
        refUserListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
        refetchData()
    })
        // *===============================================---*
        // *--------- UI ---------------------------------------*
        // *===============================================---*
    const resolveUserRoleVariant = role => {
        if (role === 'subscriber') return 'primary'
        if (role === 'author') return 'warning'
        if (role === 'maintainer') return 'success'
        if (role === 'editor') return 'info'
        if (role === 'admin') return 'danger'
        return 'primary'
    }
    const resolveUserRoleIcon = role => {
        if (role === 'subscriber') return 'UserIcon'
        if (role === 'author') return 'SettingsIcon'
        if (role === 'maintainer') return 'DatabaseIcon'
        if (role === 'editor') return 'Edit2Icon'
        if (role === 'admin') return 'ServerIcon'
        return 'UserIcon'
    }
    const resolveUserStatusVariant = status => {
        if (status === 'pending') return 'warning'
        if (status === 'active') return 'success'
        if (status === 'inactive') return 'secondary'
        return 'primary'
    }
    return {
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        refetchData,
        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
    }
}