<template>
	<b-modal id="modal-fortune-wheel-winning-view" size="xl" ref="modal-fortune-wheel-winning-view" @show="showModal" @hidden="hideModal">

		<template #modal-title>
			<h3>Fortune Wheel Winning Option Count</h3>
		</template>

		<h5><strong>Capture Title: </strong><span>{{ prop_settingObject.captureBarSay }}</span></h5>

		<b-overlay :show="isFetchingDataFromServe" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">

			<b-table hover ref="refDatatableFortuneWheelWinningView" class="position-relative" :items="dataTableRowsData" responsive :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
			</b-table>

		</b-overlay>

		<template #modal-footer="{ ok, cancel, hide }">
			<b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" @click="cancel()">
				Close
			</b-button>
		</template>

	</b-modal>
</template>
<script>
	import {
		BTable, BCardHeader, BCardTitle, BCardBody, BModal, BButton, BForm, BFormGroup, BCardText, BCol, BAlert, BRow, BInputGroupAppend, BInputGroupPrepend, BCard, BContainer, BOverlay
	} from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import fortuneWheelWinningViewTable from './fortuneWheelWinningViewTable'

	export default {
		components: {
			BTable, BModal, BButton, BForm, BFormGroup, BCardText, BCol, BAlert, BRow, BInputGroupAppend, BInputGroupPrepend, BCard, BCardBody, BCardHeader, BContainer, BCardTitle, BOverlay,
		},
		directives: {
			Ripple,
		},
		props: {
			prop_selectedFilters: {
				type: Object,
				default: () => { },
			},

			prop_settingObject: {
				type: Object,
				default: () => { },
			},
		},
		data() {
			return {
				isFetchingDataFromServe: true,
				captureTitle: '',
				dataTableRowsData: [],
			}
		},
		methods: {
			/**
			 * FOR SHOW BOOTSTRAP MODAL
			 */
			showModal() {
				this.$manageCaptureAPI.get('fetch-fortune-wheel-winning-view-count', {
					settingId: this.prop_settingObject.settingId
				}).then((response) => {
					response = response.data;
					if (response.status) {
						for (const [key, value] of Object.entries(response.fortuneWheelOptions)) {
							value.couponCode = value.couponCode ?? '-'
							value.validTill = value.validTill ?? '-'
							value.validTill = value.validTill ?? '-'
							value.winningInterval = value.winningInterval ?? '-'
							value.total_winning = value.total_winning ?? '-'
							this.dataTableRowsData.push(value);
						}
					}
					// if (response.status) {
					// 	this.autoEnableDateTime = (response.setting.auto_enable_date_time) ? moment(response.setting.auto_enable_date_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm a') : '';
					// 	this.autoDisableDateTime = (response.setting.auto_disable_date_time) ? moment(response.setting.auto_disable_date_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm a') : '';
					// 	this.captureStatus = response.status
					// } else {
					// 	this.$toast({
					// 		component: ToastificationContent,
					// 		props: {
					// 			title: 'Notification',
					// 			icon: 'BellIcon',
					// 			variant: 'danger',
					// 			text: response.message,
					// 		},
					// 	});
					// }
					this.isFetchingDataFromServe = false;
				});
			},

			/**
			 * FOR HIDE BOOTSTRAP MODAL
			 */
			hideModal() {
				this.dataTableRowsData = []
				this.$emit('onhideModalFortuneWheelOptionsEvent', true);
			},

			/**
			 * FOR HIDE DATE/TIME POPUP (ACTIVE SCHEDULE)
			 */
			hideActiveScheduleDateAndTimePopup() {
			},

			/**
			 * FOR HIDE DATE/TIME POPUP (PAUSE SCHEDULE)
			 */
			hidePauseScheduleDateAndTimePopup() {
			},

			/**
			 * FOR STORE ACTIVE OR PAUSE CAPTURE SCHEDULE
			 */
			saveScheduleCapture(scheduleType) {

				/* SUBMIT OBJECT : START */
				let submitData = new Object();
				submitData['settingId'] = this.prop_settingObject.settingId;

				if (scheduleType == 'active') { /* FOR ACTIVE SCHEDULE-CAPTURE */
					submitData['enable_setting_time'] = this.autoEnableDateTime ?? ''
				} else { /* FOR PAUSE SCHEDULE-CAPTURE */
					submitData['disable_setting_time'] = this.autoDisableDateTime ?? ''
				}
				/* SUBMIT OBJECT : END */

				this.$manageCaptureAPI.post('save-active-pause-schedule-capture', submitData).then((response) => {
					response = response.data;

					if (response.status) {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'success',
								text: response.message,
							},
						});
						this.$bvModal.hide('modal-fortune-wheel-winning-view');
						submitData['scheduleType'] = scheduleType
						this.$emit('onSaveCaptureScheduleEvent', submitData);
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
				});
			}
		},
		computed: {
			isDarkMode() {

			}
		},
		watch: {
			/**
			 * FOR SELCTED FILTER LIKE CURRENT TAB AND CURRENT DATE TYPE
			 */
			prop_selectedFilters: (newVal) => {
				return newVal;
			},

			/**
			 * FOR SETTING OBJECT
			 */
			prop_settingObject: (newVal) => {
				return newVal;
			},
		},
		setup() {

			const {
				tableColumns,
				perPage,
				currentPage,
				totalUsers,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refDatatableFortuneWheelWinningView,
				refetchData,

				// UI
				resolveUserRoleVariant,
				resolveUserRoleIcon,
				resolveUserStatusVariant,

				// Extra Filters
			} = fortuneWheelWinningViewTable()

			return {
				tableColumns,
				perPage,
				currentPage,
				totalUsers,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refDatatableFortuneWheelWinningView,
				refetchData,

				// UI
				resolveUserRoleVariant,
				resolveUserRoleIcon,
				resolveUserStatusVariant,
			}
		},
	}
</script>