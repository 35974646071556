<template>
	<b-card no-body class="">
		<b-card-header>
			<b-card-title>Filters</b-card-title>
		</b-card-header>
		<b-card-body class="">
			<b-row>
				<b-col sm="12" md="6" lg="3" xl="3">
					<label>Conversion </label>
					<Select2 v-model="dateTypeValue" :options="dateTypeOptions" @change="dropDownDateTypeChangeEvent($event)" />
				</b-col>
				<b-col sm="12" md="6" lg="3" xl="3" class="mt-2" v-if="dateTypeValue == 'custom_date'" >
					<VueCtkDateTimePicker v-model="customDateTypeFilter" :overlay="true" :noShortcuts="true" :range="true" :onlyDate="true" :time="false" format="MM/DD/YYYY" formatted="ll" @is-hidden="onHideChangeCustomDateEvent" />
				</b-col>

				<!-- START : FOR ACTIVE TIME FRAME FILTER -->
				<b-col sm="12" md="6" lg="3" xl="3" v-if="isTimeFrameShow">
					<label>Time Frame </label>
					<Select2 v-model="timeFrameDateValue" :options="dateTypeTimeFrameOptions" @change="ActivetTimeFrameFilterDropDown($event)"  placeholder="Select Time Frame" :settings="{ allowClear: true }"/>
				</b-col>
				<b-col sm="12" md="6" lg="3" xl="3" class="mt-2"  v-if="timeFrameDateValue == 'custom_date' && isTimeFrameShow ">
					<VueCtkDateTimePicker  v-model="customTimeframeDateFilter" :overlay="true" :noShortcuts="true" :range="true" :onlyDate="true" :time="false" format="MM/DD/YYYY" formatted="ll" @is-hidden="onHideChangeCustomDateEvent"  :max-date="maxDate" />
				</b-col>
				<!-- END : FOR ACTIVE TIME FRAME FILTER -->
			</b-row>
			<b-row>
				<!-- UI RELATED CHANGES -->
				<b-col xl="2" sm="4" md="5" class="p-1 m-1 cursor-pointer d-flex mb-2 btn btn-outline-primary btn-sm" v-for="(item, index) in statisticsItems" :class="statisticsItems[index].customClass" :key="statisticsItems[index].icon" @click="statisticItem(index);">
					<b-media no-body>
						<b-media-aside class="mr-2">
							<b-avatar size="50" :variant="statisticsItems[index].color">
								<feather-icon size="30" :icon="statisticsItems[index].icon" />
							</b-avatar>
						</b-media-aside>
						<b-media-body class="my-auto">
							<h4 class="font-weight-bolder mb-0"> {{ statisticsItems[index].subtitle }} </h4>
						</b-media-body>
					</b-media>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>
<script>
	import dateTypeListOptions from "@/libs/dateTypeListOptions";
	import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink } from 'bootstrap-vue';

	export default {
		components: {
			BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BAvatar, BMediaAside, BMediaBody,
		},
		props: {
		},
		data() {
			return {
				dateTypeValue: '',
				timeFrameDateValue: '',
				customDateTypeFilter: '',
				customTimeframeDateFilter: '',
				isTimeFrameShow : true,
				selected_statistic_index: 'active',
				statisticsItems: {
					'active': {
						name: 'active',
						icon: "PlayIcon",
						color: "light-primary",
						title: 0,
						subtitle: "Active",
						customClass: "active",
					},
					'paused': {
						name: 'paused',
						icon: "PauseIcon",
						color: "light-primary",
						title: 0,
						subtitle: "Paused",
						customClass: "",
					},
					'scheduled': {
						name: 'scheduled',
						icon: "WatchIcon",
						color: "light-primary",
						title: 0,
						subtitle: "Scheduled",
						customClass: "",
					},
					'all': {
						name: 'all',
						icon: "ListIcon",
						color: "light-primary",
						title: 0,
						subtitle: "All",
						customClass: "",
					}
				},
				maxDate: new Date().toISOString().substr(0, 10)
			}
		},
		watch: {
		},
		beforeCreate() {
		},
		created() {
			this.getDefaultFilterElements();
			this.submitFilters();
		},
		mounted() {
		},
		methods: {
			dropDownDateTypeChangeEvent(val) {
				if(this.timeFrameDateValue){
					if (val != 'custom_date') {
						this.submitFilters('active_time_frame');
						/* STORE FILTER IN VUEX AND DB : START */
						this.$root.setDefaultFilterElements({
							page: 'siteManage',
							filterObject: {
								'date_type': val,
								'date_time_frame': this.timeFrameDateValue,
							},
						});
						/* STORE FILTER IN VUEX AND DB : END */
					} else {
						this.dateTypeValue = val;
						this.submitFilters('active_time_frame');
					}
				}else{

					if (val != 'custom_date') {
						this.submitFilters();
						/* STORE FILTER IN VUEX AND DB : START */
						this.$root.setDefaultFilterElements({
							page: 'siteManage',
							filterObject: {
								'date_type': val,
							},
						});
						/* STORE FILTER IN VUEX AND DB : END */
					} else {
						this.dateTypeValue = val;
						this.submitFilters();
					}
				}
			},

			ActivetTimeFrameFilterDropDown(val) {
				if (val != 'custom_date') {
					this.submitFilters('active_time_frame');
					/* STORE FILTER IN VUEX AND DB : START */
					this.$root.setDefaultFilterElements({
						page: 'siteManage',
						filterObject: {
							'date_time_frame': val,
						},
					});
					/* STORE FILTER IN VUEX AND DB : END */
				} else {
					this.timeFrameDateValue = val;
					this.submitFilters('active_time_frame');
				}
			},

			onHideChangeCustomDateEvent() {
				if (!_.isEmpty(this.customDateTypeFilter) || !_.isEmpty(this.customTimeframeDateFilter)) {

					if(!_.isEmpty(this.customTimeframeDateFilter)){
						this.submitFilters('active_time_frame');
					}else{
						this.submitFilters();
					}
					/* STORE FILTER IN VUEX AND DB : START */
					this.$root.setDefaultFilterElements({
						page: 'siteManage',
						filterObject: {
							'date_type': this.dateTypeValue,
							'date_time_frame': this.timeFrameDateValue,
							'date_type_custom_filter': this.customDateTypeFilter,
							'custom_date_filter': this.customTimeframeDateFilter,
						},
					});
					/* STORE FILTER IN VUEX AND DB : END */
				}
			},
			statisticItem(index) { /* SELECT STATISTIC ITEM VIEWS CALLS EMAILS COLLECTED CLICKS SOCIAL CONVERSIONS */
				if(index == "scheduled" || index == "all"){
					this.isTimeFrameShow = false;
					this.timeFrameDateValue = '';
				}else{
					this.isTimeFrameShow = true;
				}
				if (!index) return false;
				this.selected_statistic_index = index;
				_(this.statisticsItems).each(x => x.customClass = '');
				this.statisticsItems[this.selected_statistic_index].customClass = "active";
				if(this.timeFrameDateValue != ""){
					this.submitFilters('active_time_frame');
				}else{
					this.submitFilters();
				}
			},

			submitFilters(active_inactive) {
				this.$emit('selectedFilter', {
					'site': this.$root.appSiteObject,
					'date_type': this.dateTypeValue,
					'date_time_frame': this.timeFrameDateValue,
					'custom_start_date': !_.isEmpty(this.customDateTypeFilter) ? this.customDateTypeFilter.start : '',
					'custom_end_date': !_.isEmpty(this.customDateTypeFilter) ? this.customDateTypeFilter.end : '',
					'start_date': !_.isEmpty(this.customTimeframeDateFilter) ? this.customTimeframeDateFilter.start : '',
					'end_date': !_.isEmpty(this.customTimeframeDateFilter) ? this.customTimeframeDateFilter.end : '',
					'statisticsItems': this.statisticsItems[this.selected_statistic_index],
					'filter_type' : active_inactive ?? ''
				});
			},

			/**
			* GET FILTER FROM VUEX AND SET INTO ELEMENTS
			*/
			getDefaultFilterElements() {
				let filterObject = this.$store.getters['appModuleFilters/getFilter']({
					page: 'siteManage',
				});
				/* FETCH FILTER FROM VUEX : END */
				this.dateTypeValue = filterObject.date_type;
				this.timeFrameDateValue = '';
				this.customDateTypeFilter = filterObject.date_type_custom_filter;
				this.customTimeframeDateFilter = filterObject.custom_date_filter;
			}
		},
		setup() {

			const {
				dateTypeOptions,
			} = dateTypeListOptions()

			/**
			 * IN TODAY we aready get all captures, so Removed ALL TIME label
			 * TO REMOVE ALL TIME LABEL
			 */
			const dateTypeTimeFrameOptions = _.dropRight(dateTypeOptions); /*  */

			return {
				dateTypeOptions,
				dateTypeTimeFrameOptions
			}
		},
	}
</script>