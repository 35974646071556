<template>
    <section id="dashboard-ecommerce">
        <b-row class="match-height">
            <b-col lg="12" xl="12" md="12" sm="12">
                <b-overlay :show="isFetchingRecordsFromServer" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">
                    <filters @selectedFilter="applyFiltersEvent($event)"></filters>
                </b-overlay>
            </b-col>
        </b-row>
        <b-row class="match-height">
            <b-col lg="12" xl="12" md="12" sm="12" class="pb-3">

                <b-card no-body v-for="(settingObject, settingIndex) of siteSettingArray" v-bind:key="settingIndex" :id="`card_section_${settingIndex}`">

                    <template #header>

                        <div class="d-flex justify-content-between w-100">

                            <!-- FOR HEADER TITLE : START -->
                            <h4 class="m-50"><strong><span v-if="!settingObject.targetingId">Default : </span>{{ settingObject.nameWhoShouldSee }}</strong></h4>
                            <!-- FOR HEADER TITLE : START -->

                            <!-- FOR TARGET RULES : START -->
                            <div v-if="settingObject.targetingId" class="d-flex align-items-center justify-content-end">
                                <div class="dropdown" @click="selectedTargetingRule = settingObject.targetingId">
                                    <b-dropdown variant="link" no-caret toggle-class="p-0 mr-1" right>
                                        <template #button-content>
                                            <feather-icon icon="SettingsIcon" size="18" class="align-middle" />
                                        </template>
                                        <b-dropdown-item v-b-modal.modal-add-edit-rules>
                                            <feather-icon icon="Edit2Icon" size="18" class="align-middle text-body" /> Edit Rule
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="deleteTargetingRules(settingObject.targetingId)">
                                            <feather-icon icon="TrashIcon" size="18" class="align-middle text-body" /> Delete Rule
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </div>
                            <!-- FOR TARGET RULES : END -->
                        </div>
                    </template>

                    <!-- FOR DISPLAY ALL CAPTURES : START -->
                    <b-overlay :show="loadCaptureSpinner[settingIndex]" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">

                        <b-table ref="'reference'+settingIndex" class="position-relative" :items="dataTableRowsData[settingIndex]" responsive :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">

                            <!-- Column: User -->
                            <template #cell(subGoal)="data">
                                <b-avatar rounded size="38" :variant="subGoalTypeIcons[data.item.subGoal].avatarVariant" :id="`setting-goal-${data.item.settingId}`">
                                    <feather-icon :icon="subGoalTypeIcons[data.item.subGoal].avatar" :title="subGoalTypeIcons[data.item.subGoal].title" size="23" class="" />
                                </b-avatar>
                                <b-tooltip :target="`setting-goal-${data.item.settingId}`" placement="top">
                                    <p class="mb-0">
                                        {{ subGoalTypeIcons[data.item.subGoal].title }}
                                    </p>
                                </b-tooltip>
                            </template>

                            <!-- Column: Role -->
                            <template #cell(styleType)="data">
                                <b-avatar rounded size="38" variant="light-primary" :id="`setting-style-${data.item.settingId}`">
                                    <font-awesome-icon :icon="styleTypeIcons[data.item.styleType].avatar" :title="styleTypeIcons[data.item.styleType].title" class="fa-2x" />
                                </b-avatar>
                                <b-tooltip :target="`setting-style-${data.item.settingId}`" placement="top">
                                    <p class="mb-0">
                                        {{ styleTypeIcons[data.item.styleType].title }}
                                    </p>
                                </b-tooltip>
                            </template>

                            <!-- Column: Status -->
                            <template #cell(status)="data">
                                <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.status)}`" class="text-capitalize">
                                    {{ data.item.status }}
                                </b-badge>
                            </template>

                            <!-- Column: views -->
                            <template #cell(views)="data">
                                <feather-icon v-if="data.item.views == 'loading'" icon="LoaderIcon" size="18" class="spinner" />
                                <div v-if="data.item.views != 'loading'" v-html="data.item.views"></div>
                            </template>

                            <!-- Column: conversions -->
                            <template #cell(conversions)="data">
                                <feather-icon v-if="data.item.conversions == 'loading'" icon="LoaderIcon" size="18" class="spinner" />
                                <div v-if="data.item.conversions != 'loading'">
                                    <button class="btn btn-sm btn btn-outline-primary text-nowrap cursor-pointer" v-if="data.item.conversions == 'empty'" @click="fetchViewAndConversionForTable(data.item)">
                                        <feather-icon icon="CalendarIcon" size="18" class="mr-50" title="View & Conversion" />
                                        <span class="align-text-top text-capitalize">View & Conversion</span>
                                    </button>
                                    <div v-else="data.item.conversions" v-html="data.item.conversions"></div>
                                </div>
                            </template>

                            <!-- Column: conversionsPercentage -->
                            <template #cell(conversionsPercentage)="data">
                                <feather-icon v-if="data.item.conversionsPercentage == 'loading'" icon="LoaderIcon" size="18" class="spinner" />
                                <div v-if="data.item.conversionsPercentage != 'loading'" v-html="data.item.conversionsPercentage"></div>
                            </template>

                            <!-- Column: comparison -->
                            <template #cell(comparison)="row">
                                <feather-icon icon="PieChartIcon" :title="`Show Desktop/Mobile`" size="28" :class="{ 'cursor-pointer': true, 'text-primary': row.item.isActive }" @click="openComparisonChartSideModal(row.item)" :id="`setting-comparison-${row.item.settingId}`" />
                                <b-tooltip :target="`setting-comparison-${row.item.settingId}`" placement="top">
                                    <p class="mb-0">Show Chart</p>
                                </b-tooltip>
                            </template>

                            <!-- Column: Status -->
                            <template #cell(duplicate_alphabet)="data">
                                <div v-html="data.item.duplicate_alphabet"></div>
                            </template>

                            <!-- Column: Note -->
                            <template #cell(site_setting_notes)="row">
                                <feather-icon icon="FileTextIcon" :title="`Capture Note`" size="28" @click="row.toggleDetails" class="cursor-pointer" :id="`setting-notes-${row.item.settingId}`" />
                                <b-tooltip :target="`setting-notes-${row.item.settingId}`" placement="top">
                                    <p class="mb-0">Show Notes</p>
                                </b-tooltip>
                            </template>

                            <template #row-details="row">

                                <b-card class="text-center border-top my-0">

                                    <b-card-text v-if="row.item.site_setting_notes" v-html="row.item.site_setting_notes"></b-card-text>

                                    <b-card-text v-if="row.item.site_setting_notes == ''">
                                        <em>Empty Note</em>
                                    </b-card-text>

                                    <b-button variant="danger" size="sm" @click="row.toggleDetails">Close Note</b-button>

                                </b-card>

                            </template>

                            <!-- Column: Actions -->
                            <template #cell(actions)="data">
                                <b-dropdown variant="link" no-caret right text="Right align">

                                    <template #button-content>
                                        <feather-icon icon="ListIcon" size="16" class="align-middle text-body" />
                                    </template>

                                    <!-- FOR EDIT CAPTURE -->
                                    <b-dropdown-item @click="submitActivePause(data.item, data.index, settingIndex, settingObject, $event)">
                                        <feather-icon v-if="data.item.status == '1'" icon="PauseIcon" />
                                        <feather-icon v-else icon="PlayIcon" />
                                        <span class="align-middle ml-50">
                                            {{ data.item.status == '1' ? "Paused": "Active"}}
                                        </span>
                                    </b-dropdown-item>

                                    <!-- FOR EDIT CAPTURE -->
                                    <b-dropdown-item :to="{ name: 'capture-setting', params: { setting_id: data.item.settingId } }">
                                        <feather-icon icon="EditIcon" />
                                        <span class="align-middle ml-50">Edit</span>
                                    </b-dropdown-item>

                                    <!-- FOR DELETE CAPTURE -->
                                    <b-dropdown-item @click="deleteSiteSetting(data.item, settingIndex, settingObject)">
                                        <feather-icon icon="TrashIcon" />
                                        <span class="align-middle ml-50">Delete</span>
                                    </b-dropdown-item>

                                    <!-- FOR DUPLICATE CAPTURE [CREATE VARIATION] -->
                                    <b-dropdown-item :to="{ name: 'capture-setting-clone', params: { setting_id: data.item.settingId } }">
                                        <feather-icon icon="CopyIcon" />
                                        <span class="align-middle ml-50">Create Variation</span>
                                    </b-dropdown-item>

                                    <!-- FOR FORTUNE WHEEL WINNING VIEW MODAL -->
                                    <b-dropdown-item v-if="data.item.styleType == 5" @click="showFortuneWheelWinningViewModal(data.item, settingIndex, settingObject)">
                                        <font-awesome-icon icon="fa-solid fa-medal" />
                                        <span class="align-middle ml-50">Winning Count</span>
                                    </b-dropdown-item>

                                    <!-- FOR CAPTURE SCHEDULE MODAL -->
                                    <b-dropdown-item @click="showCaptureScheduleModal(data.item, settingIndex, settingObject)">
                                        <feather-icon icon="CalendarIcon" />
                                        <span class="align-middle ml-50">Schedule</span>
                                    </b-dropdown-item>

                                </b-dropdown>
                            </template>
                        </b-table>
                    </b-overlay>
                    <!-- FOR DISPLAY ALL CAPTURES : END -->

                </b-card>
            </b-col>
        </b-row>

        <!-- FOR CAPTURE VIEW COMPARISON CHART -->
        <comparison-chart v-model="showComparisonChartActive" :prop_selectedFilters="sendFiltersDataObject" :prop_comparisonChartDataObject="sendComparisonChartDataObject" @refreshSiteList="getSitesAndUsers()" @addActiveClass="activeClass"></comparison-chart>

        <!-- FOR CAPTURE ADD/EDIT RULES -->
        <add-edit-rules :prop_targetNamesObject="targeting_rules" :prop_selectedTargetingRule="selectedTargetingRule" />

        <!-- FOR ACTIVE OR PAUSE CAPTURE SCHEDULE -->
        <capture-schedule-modal v-if="this.selectedFilters" :prop_selectedFilters="this.selectedFilters" :prop_settingObject="settingObjectForScheduleModal" @onSaveCaptureScheduleEvent="onSaveCaptureScheduleEventHandler" />

        <!-- FOR ACTIVE OR PAUSE CAPTURE SCHEDULE -->
        <fortune-wheel-winning-view-modal v-if="this.selectedFilters" :prop_selectedFilters="this.selectedFilters" :prop_settingObject="settingObjectForFortuneWheelWinningViewModal" @onhideModalFortuneWheelOptionsEvent="onhideModalFortuneWheelOptionsEventHandler" />

    </section>
</template>

<script>
    import { BRow, BCol, BCard, BCardGroup, BCardText, BOverlay, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BTooltip } from 'bootstrap-vue'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import { ref, onUnmounted } from '@vue/composition-api'
    import { avatarText } from '@core/utils/filter'
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import Filters from "./Filters.vue";
    import store from '@/store'
    import captureIcons from "@/libs/capture-Icons"
    import captureListModule from './captureListModule'
    import userStoreModule from './userStoreModule'
    import ComparisonChart from './ComparisonChart'
    import AddEditRules from '@/views/capture-setting/tagret-components/AddEditRules.vue'
    import CaptureScheduleModal from './CaptureScheduleModal.vue'
    import FortuneWheelWinningViewModal from './FortuneWheelWinningViewModal.vue'

    export default {
        components: {
            BRow, BCol, BCard, BCardGroup, BCardText, BOverlay, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BTooltip,
            Filters,
            AppCollapse,
            AppCollapseItem,
            ComparisonChart,
            AddEditRules,
            CaptureScheduleModal,
            FortuneWheelWinningViewModal
        },
        data() {
            return {
                isFetchingRecordsFromServer: false,
                loadCaptureSpinner: {},
                siteSettingArray: [],
                selectedFilters: {},
                siteObject: {},
                dataTableRowsData: {},
                isVerticalMenuCollapsed: this.$store.state.verticalMenu.isVerticalMenuCollapsed,
                showComparisonChartActive: false,
                comparisonChartDataObject: {},
                selectedTargetingRule: '',
                targeting_rules: [],
                settingObjectForScheduleModal: {},
                settingObjectForFortuneWheelWinningViewModal: {},
                settingObject: {},
                GLOBLE_captureTableRowObject: {},
            };
        },
        computed: {
            /**
             * FOR SEND DATA TO OPEN COMPARISON CHART SIDE MODAL
             */
            sendComparisonChartDataObject() {
                return this.comparisonChartDataObject;
            },

            /**
             * FOR SEND FILTERS TO OPEN COMPARISON CHART SIDE MODAL
             */
            sendFiltersDataObject() {
                return this.selectedFilters;
            }
        },
        watch: {
        },
        methods: {
            activeClass(res) {
                if (res != true) {
                    this.settingObject.isActive = '';
                }
            },
            applyFiltersEvent(filterObject) {
                this.siteSettingArray = [];
                this.selectedFilters = filterObject; /* ADD FILERTS TO OBJECT */
                this.siteObject = filterObject.site;
                this.fetchSiteCaptures();
            },

            /* START : FETCH SITE CAPTURE RULES WHILE PAGE IS LOAD */
            fetchSiteCaptures() {
                this.isFetchingRecordsFromServer = true;
                this.$manageCaptureAPI.post('fetch-site-captures', {
                    'auth_id': this.$root.authUserObject.id ?? '',
                    'site_id': this.siteObject.siteId ?? '',
                    'date_type': this.selectedFilters.date_type ?? '',
                    'custom_start_date': this.selectedFilters.custom_start_date ?? '',
                    'custom_end_date': this.selectedFilters.custom_end_date ?? '',
                    'tab': this.selectedFilters.statisticsItems.name ?? '',
                    'date_time_frame': this.selectedFilters.date_time_frame ?? '',
                    'start_date': this.selectedFilters.start_date ?? '',
                    'end_date': this.selectedFilters.end_date ?? '',
                    'filter_type': this.selectedFilters.filter_type ?? ''
                }).then((response) => {
                    response = response.data;
                    if (response.status && response.site_settings.ruleElements.length != 0) {
                        for (const [key, value] of Object.entries(response.site_settings.ruleElements)) {
                            this.siteSettingArray.push(value);
                        }
                        this.siteSettingArray = _.orderBy(this.siteSettingArray, ['targetingId'], ['desc']); /* FOR "Everyone" MOVE TOP */
                        this.targeting_rules = response.site_settings.target_names;

                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Notification',
                                icon: 'BellIcon',
                                variant: 'danger',
                                text: response.message,
                            },
                        });
                    }
                    this.isFetchingRecordsFromServer = false;
                    for (const [key, val] of Object.entries(this.siteSettingArray)) {
                        this.$set(this.dataTableRowsData, key, []);
                        this.fetchCaptures(val, key);
                    }
                    this.$swal.close(); /* FOR CLOSE SWEET ALERT WHEN DELETE RULES */
                });
            },
            /* END : FETCH SITE CAPTURE RULES WHILE PAGE IS LOAD */

            /* START : FETCH CAPTURES WHEN CLICK ON THE RULES */
            fetchCaptures(settingObject, keyIndex) {
                this.loadCaptureSpinner[keyIndex] = true;
                this.$manageCaptureAPI.post('fetch-captures', {
                    'auth_id': this.$root.authUserObject.id ?? '',
                    'site_id': this.siteObject.siteId ?? '',
                    'date_type': this.selectedFilters.date_type ?? '',
                    'date_time_frame': this.selectedFilters.date_time_frame ?? '',
                    'custom_start_date': this.selectedFilters.custom_start_date ?? '',
                    'custom_end_date': this.selectedFilters.custom_end_date ?? '',
                    'start_date': this.selectedFilters.start_date ?? '',
                    'end_date': this.selectedFilters.end_date ?? '',
                    'tab': this.selectedFilters.statisticsItems.name ?? '',
                    'rules': JSON.stringify(settingObject) ?? '',
                    'filter_type': this.selectedFilters.filter_type ?? ''
                }).then((response) => {
                    response = response.data;
                    if (response.status) {
                        if (_.isEmpty(response.site_settings.siteElements)) {
                            this.$el.querySelector('#card_section_'+keyIndex).remove();
                        } else {
                            this.$set(this.dataTableRowsData, keyIndex, [...response.site_settings.siteElements]);
                        }
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Notification',
                                icon: 'BellIcon',
                                variant: 'danger',
                                text: response.message,
                            },
                        });
                    }
                    this.$set(this.loadCaptureSpinner, keyIndex, false);
                    this.$swal.close(); /* FOR CLOSE SWEET ALERT WHEN DELETE CAPTURE */
                });
            },
            /* END : FETCH CAPTURES WHEN CLICK ON THE RULES */

            deleteSiteSetting(settingObject, settingIndex, settingRuleObject) {

                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,

                }).then(result => {

                    if (result.value) {

                        this.$swal({
                            allowOutsideClick: false,
                            didOpen: () => {
                                this.$swal.showLoading();
                            },
                            title: 'Please wait',
                            text: 'Your request is processing',
                        });

                        this.$manageCaptureAPI.post('delete-site-setting', {
                            'auth_id': this.$root.authUserObject.id ?? '',
                            'site_id': settingObject.siteId ?? '',
                            'setting_id': settingObject.settingId ?? '',
                        }).then((response) => {
                            response = response.data;

                            if (response.status) {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Notification',
                                        icon: 'BellIcon',
                                        variant: 'success',
                                        text: response.message,
                                    },
                                });
                                this.fetchCaptures(settingRuleObject, settingIndex); /* TO REFERSH CURRENT TABLE */
                            } else {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Notification',
                                        icon: 'BellIcon',
                                        variant: 'danger',
                                        text: response.message,
                                    },
                                });
                            }
                        });
                    }
                })
            },

            /**
             * FOR DELETE TARGETING RULES AND UPDATE WITH DB
             */
            deleteTargetingRules(targetingId) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to recover all the capture settings and contacts once you delete this rule.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,

                }).then(result => {
                    if (result.value) {

                        this.$swal({
                            allowOutsideClick: false,
                            didOpen: () => {
                                this.$swal.showLoading();
                            },
                            title: 'Please wait',
                            text: 'Your request is processing',
                        });

                        this.$manageCaptureAPI.post('delete-targeting-rule', {
                            'site_id': this.$root.appSiteObject.siteId ?? '',
                            'targeting_Id': targetingId ?? '',
                        }).then((response) => {
                            response = response.data;
                            this.applyFiltersEvent(this.selectedFilters);
                            if (response.status) {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Notification',
                                        icon: 'BellIcon',
                                        variant: 'success',
                                        text: response.message,
                                    },
                                });
                            } else {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Notification',
                                        icon: 'BellIcon',
                                        variant: 'danger',
                                        text: response.message,
                                    },
                                });
                            }
                        });

                    }
                })
            },

            /**
             * FOR ACTIVE AND PAUSE CAPTURE
             */
            submitActivePause(settingObject, index, settingIndex, settingRuleObject, event) {
                /* USER HAVE SELECTED ACTIVE OR PAUSE : START */
                let changeStatus = 1;
                if (settingObject.status == '1') {
                    changeStatus = 0;
                }
                /* USER HAVE SELECTED ACTIVE OR PAUSE : END */

                this.isFetchingRecordsFromServer = true;
                this.$manageCaptureAPI.post('update-status-captures', {
                    'elementid': settingObject.settingId ?? '',
                    'siteId': settingObject.siteId ?? '',
                    'status': changeStatus,
                }).then((response) => {

                    if (response.data.paused_date != "-") {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Paused',
                                icon: 'BellIcon',
                                variant: 'success',
                                text: 'Capture has been paused',
                            },
                        })
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Activated',
                                icon: 'BellIcon',
                                variant: 'success',
                                text: 'Capture has been activated',
                            },
                        })
                    }
                    this.fetchCaptures(settingRuleObject, settingIndex); /* TO REFERSH CURRENT TABLE */
                    this.isFetchingRecordsFromServer = false;
                });

            },

            /**
             * FOR OPNE COMPARISON CHART SIDE MODAL WHEN CLICK ON THE TABLE ROW INTO COMPARISON COLUMN
             */
            openComparisonChartSideModal(settingObject) {
                this.settingObject = settingObject;
                settingObject.isActive = true;
                this.comparisonChartDataObject = settingObject;
                this.showComparisonChartActive = true;
            },

            /**
             * FOR FETCH VIEW AND CONVERSION
             */
            fetchViewAndConversionForTable(settingObject, rowIndex) {

                /* SHOW LOADER : START */
                settingObject.views = "loading";
                settingObject.conversions = "loading";
                settingObject.conversionsPercentage = "loading";
                /* SHOW LOADER : END */

                this.$manageCaptureAPI.post('fetch-site-setting-view-conversion', {
                    settingId: settingObject.settingId,
                    siteId: settingObject.siteId,
                    subGoal: settingObject.subGoal,
                    date_type: this.selectedFilters.date_type,
                    custom_start_date: this.selectedFilters.custom_start_date,
                    custom_end_date: this.selectedFilters.custom_end_date,
                }).then((response) => {

                    response = response.data;

                    this.$set(settingObject, 'views', response.visitor);
                    this.$set(settingObject, 'conversions', response.totaldata);

                    let conversionPercentage = parseFloat(0.0);
                    if (parseFloat(response.totaldata) > 0 && parseFloat(response.visitor) > 0) {
                        conversionPercentage = ((parseFloat(response.totaldata) * 100) / parseFloat(response.visitor));
                    }
                    this.$set(settingObject, 'conversionsPercentage', `${conversionPercentage.toFixed(2)}%`);
                });
            },

            /**
             * FOR SHOW CAPTURE SCHEDULE MODAL
             * SHOW MODAL : src\views\manage-site\CaptureScheduleModal.vue
             */
            showCaptureScheduleModal(settingObject, settingIndex, settingRuleObject) {
                this.settingObjectForScheduleModal = settingObject
                this.GLOBLE_captureTableRowObject = {
                    'settingIndex': settingIndex,
                    'settingRuleObject': settingRuleObject
                };
                this.$nextTick(() => {
                    this.$bvModal.show('modal-capture-schedule');
                });
            },

            /**
             * FOR SHOW FORTUNE WHEEL WINNING VIEW MODAL
             * SHOW MODAL : src\views\manage-site\FortuneWheelWinningViewModal.vue
             */
            showFortuneWheelWinningViewModal(settingObject, settingIndex, settingRuleObject) {
                this.settingObjectForFortuneWheelWinningViewModal = settingObject;
                this.GLOBLE_captureTableRowObject = {
                    'settingIndex': settingIndex,
                    'settingRuleObject': settingRuleObject
                };
                this.$nextTick(() => {
                    this.$bvModal.show('modal-fortune-wheel-winning-view');
                });
            },

            /**
             * FOR SAVE CAPTURE SCHEDULE and EVENT HANDLER
             * CALLING EVENT BY: src\views\manage-site\CaptureScheduleModal.vue
             */
            onSaveCaptureScheduleEventHandler(scheduleObject) {
                /**
                 * FOR REMOVE CAPTURE TABLE ROW : START
                 */
                if (this.selectedFilters.statisticsItems.name == 'active') { /* IF SELCTED FILTER TILE IS "active" */
                    if (scheduleObject.scheduleType == 'active') {
                        if (!_.isEmpty(scheduleObject.enable_setting_time) || !_.isEmpty(scheduleObject.disable_setting_time)) {
                            let rowIndex = _.findIndex(this.dataTableRowsData[this.GLOBLE_captureTableRowObject.settingIndex], function (o) { return o.settingId == scheduleObject.settingId; });
                            this.dataTableRowsData[this.GLOBLE_captureTableRowObject.settingIndex].splice(rowIndex, 1); /* REMOVE ROW */
                        }
                    }
                } else if (this.selectedFilters.statisticsItems.name == 'paused') {
                    if (scheduleObject.scheduleType == 'active') {
                        if (!_.isEmpty(scheduleObject.enable_setting_time) || !_.isEmpty(scheduleObject.disable_setting_time)) {
                            let rowIndex = _.findIndex(this.dataTableRowsData[this.GLOBLE_captureTableRowObject.settingIndex], function (o) { return o.settingId == scheduleObject.settingId; });
                            this.dataTableRowsData[this.GLOBLE_captureTableRowObject.settingIndex].splice(rowIndex, 1); /* REMOVE ROW */
                        }
                    }
                } else if (this.selectedFilters.statisticsItems.name == 'scheduled') {
                    if ((scheduleObject.scheduleType == 'active' && _.isEmpty(scheduleObject.enable_setting_time)) || (scheduleObject.scheduleType == 'pause' && _.isEmpty(scheduleObject.disable_setting_time))) {
                        let rowIndex = _.findIndex(this.dataTableRowsData[this.GLOBLE_captureTableRowObject.settingIndex], function (o) { return o.settingId == scheduleObject.settingId; });
                        this.dataTableRowsData[this.GLOBLE_captureTableRowObject.settingIndex].splice(rowIndex, 1); /* REMOVE ROW */
                    }
                }
                /**
                 * FOR REMOVE CAPTURE TABLE ROW : END
                 */
                this.settingObjectForScheduleModal = {};
                this.fetchCaptures(this.GLOBLE_captureTableRowObject.settingRuleObject, this.GLOBLE_captureTableRowObject.settingIndex);
                this.GLOBLE_captureTableRowObject = {};
            },

            /**
             * FOR FORTUNE WHEEL WINNING VIEW MODAL EVENT HANDLER
             * CALLING EVENT BY: src\views\manage-site\FortuneWheelWinningViewModal.vue
             */
            onhideModalFortuneWheelOptionsEventHandler() {
                this.settingObjectForFortuneWheelWinningViewModal = {};
            }
        },
        created() {
            // Set `appConfig` when page/SFC is created
            this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
        },
        mounted() {
            var isTab = (/ipad/).test(navigator.userAgent.toLowerCase());
            /* FOR CAPTURE TABLE WITH MENU DROPDOWN : START */
            if (this.$isMobile() == false && isTab == false) { /* IF DEVICE IS DESKTOP THEN ONLY HIDE MENU */

                this.$root.$on('bv::dropdown::show', bvEvent => { /* SHOW DROPDOWN MENU PROPERLY WHEN ONLY ONE SETTING IS THERE IN TABLE */
                    let currentDropdown = bvEvent.target;
                    if (currentDropdown.closest('.table-responsive')) {
                        currentDropdown.closest('.table-responsive').style.overflow = "inherit"
                    }
                })
                this.$root.$on('bv::dropdown::hide', bvEvent => {
                    let currentDropdown = bvEvent.target;
                    if (currentDropdown.closest('.table-responsive')) {
                        currentDropdown.closest('.table-responsive').style.overflow = "auto"
                    }
                })
            }
            /* FOR CAPTURE TABLE WITH MENU DROPDOWN : END */
        },
        setup() {
            const USER_APP_STORE_MODULE_NAME = 'app-user'

            // Register module
            if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
            })

            const {
                styleTypeIcons,
                subGoalTypeIcons
            } = captureIcons();

            const {
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refUserListTable,
                refetchData,

                // UI
                resolveUserRoleVariant,
                resolveUserRoleIcon,
                resolveUserStatusVariant,

                // Extra Filters
            } = captureListModule()

            return {
                styleTypeIcons,
                subGoalTypeIcons,
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refUserListTable,
                refetchData,

                // Filter
                avatarText,

                // UI
                resolveUserRoleVariant,
                resolveUserRoleIcon,
                resolveUserStatusVariant,
            }
        },
        destroyed() {
            // Restore the state value of `appConfig` when page/SFC is destroyed
            // this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', this.isVerticalMenuCollapsed),
            this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', this.isVerticalMenuCollapsed)

        },
    }
</script>
<style lang="scss">
    @media screen and (max-width: 991px) {
        [dir] .dropdown-menu[x-placement=top-end] {
            max-height: 140px;
            overflow-y: auto;
        }

        [dir] .dropdown-menu[x-placement=top-end]::-webkit-scrollbar {
            width: 8px;
        }

        [dir] .dropdown-menu[x-placement=top-end]::-webkit-scrollbar-thumb {
            background-color: #7367f0;
            /* Set the color of the scrollbar thumb */
            border-radius: 4px;
            /* Set the border radius of the scrollbar thumb */
        }

        [dir] .dropdown-menu[x-placement=top-end]::-webkit-scrollbar-track {
            background-color: #f8f9fa;
            /* Set the color of the scrollbar track */
            border-radius: 4px;
            /* Set the border radius of the scrollbar track */
        }
    }

    @import "~@core/scss/base/pages/app-todo.scss";
</style>