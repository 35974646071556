<template>
	<div>
		<b-sidebar id="sidebar-task-handler" sidebar-class="sidebar-lg" :visible="showComparisonChartActive" bg-variant="white" shadow backdrop no-header right @change="(val) => { $emit('update:show-comparison-chart-active', val); }" @hidden="modalClose" @shown="modalShown">

			<template #default="{ hide }">

				<!-- Header -->
				<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
					<h5 class="mb-0">
						Mobile & Desktop Comparison
					</h5>
					<div>
						<feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="21" @click="hide" />
					</div>
				</div>

				<b-overlay :show="isFetchingRecordsFromServer" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">

					<b-card>
						<b-card-title class="mb-1">
							Mobile & Desktop Comparison
						</b-card-title>
						<b-card-sub-title class="mb-2">
							How many user have view the capture.
						</b-card-sub-title>

						<vue-apex-charts v-if="emptyConversationMessage == ''" type="donut" height="350" width="350" :options="chartOptions" :series="donutChartSeries" class="d-flex justify-content-center" />

						<b-card-title v-if="emptyConversationMessage" class="mt-2 d-flex align-items-center justify-content-center">
							<span class="text-warning"><em>{{ emptyConversationMessage }}</em></span>
						</b-card-title>

					</b-card>

				</b-overlay>

			</template>

		</b-sidebar>
	</div>
</template>

<script>
	import { BCard, BSidebar, BRow, BCol, BCardTitle, BCardSubTitle, BOverlay } from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'
	import VueApexCharts from 'vue-apexcharts'

	export default {
		components: {
			BCard, BSidebar, BRow, BCol, BCardTitle, BCardSubTitle, BOverlay,
			VueApexCharts,
		},
		directives: {
			Ripple,
		},
		model: {
			prop: 'showComparisonChartActive',
			event: 'update:show-comparison-chart-active',
		},
		props: {
			showComparisonChartActive: {
				type: Boolean,
				required: true,
			},
			prop_comparisonChartDataObject: {
				type: Object,
				default: () => { },
			},
			prop_selectedFilters: {
				type: Object,
				default: () => { },
			},
		},
		watch: {
			showComparisonChartActive(newVal, OldVal) {
				this.$emit('addActiveClass', newVal);
				return newVal;
			},
			prop_comparisonChartDataObject(newVal, OldVal) {
				return newVal;
			},
			prop_selectedFilters(newVal, OldVal) {
				return newVal;
			},
		},
		data() {
			return {
				emptyConversationMessage: '',
				isFetchingRecordsFromServer: false,
				donutChartSeries: [0, 0]
			};
		},
		methods: {

			/**
			 * OPEN LEFT SIDE MODAL AND FETCH DATA FROM SERVER
			 */
			modalShown() {
				if (this.showComparisonChartActive) {
					this.fetchSiteSettingviewComparison(this.prop_comparisonChartDataObject, this.prop_selectedFilters);
				}
			},

			/**
			 * CLOSE LEFT SIDE MODAL & CLEAR ALL DATA
			 */
			modalClose() {
				this.prop_comparisonChartDataObject.isActive = '';
				this.emptyConversationMessage = "";
			},

			/**
			 * FETCH CHART DATA FROM SERVER
			 */
			fetchSiteSettingviewComparison(settingObject, filterObject) {
				this.isFetchingRecordsFromServer = true;
				this.$manageCaptureAPI.post('fetch-site-setting-view-comparison', {
					settingId: settingObject.settingId,
					siteId: filterObject.site.siteId,
					subGoal: settingObject.subGoal,
					date_type: filterObject.date_type,
					custom_start_date: filterObject.custom_start_date,
					custom_end_date: filterObject.custom_end_date
				}).then((response) => {

					response = response.data;

					this.isFetchingRecordsFromServer = false;

					if (response.status) {

						let mobile = parseFloat(response.chartData.mobileData);
						let computer = parseFloat(response.chartData.computerData);

						/* CASE-1 */
						if (mobile == 0 && computer == 0) {
							this.donutChartSeries = [0, 0];
							this.emptyConversationMessage = response.message;
							return;
						}

						/* CASE-2 */
						if (mobile > 0 && computer < 0) {
							this.donutChartSeries = [mobile, computer];
						}

						/* CASE-3 */
						if (mobile < 0 && computer > 0) {
							this.donutChartSeries = [mobile, computer];
						}

						/* CASE-4 */
						if (mobile > 0 || computer > 0) {
							this.donutChartSeries = [mobile, computer];
						}
					} else {
						this.emptyConversationMessage = response.message;
					}
				});
			},
		},
		setup() {
			const chartOptions = {
				colors: ['#FF5C5D', '#3498DB'],
				labels: ['Mobile', 'Desktop'],
				legend: {
					show: true,
					position: 'bottom',
					fontSize: '18px',
					fontFamily: 'Montserrat',
					labels: {
						colors: ['#FF5C5D', '#3498DB'],
					}
				},
				dataLabels: {
					enabled: true,
					formatter(val) {
						return `${parseInt(Math.round(val))}%`
					},
					style: {
						colors: ['#f7f7fa', '#f7f7fa']
					}
				},
				responsive: [{
					breakpoint: 992,
					options: {
						chart: {
							height: 380,
						},
						legend: {
							position: 'bottom',
						},
					},
				}, {
					breakpoint: 576,
					options: {
						chart: {
							height: 320,
						},
						legend: {
							show: true,
						},
					},
				},
				],
			};
			return {
				chartOptions
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '~@core/scss/base/bootstrap-extended/include';
</style>